@import "src/common/scss/variables";

$header-padding: 15px;

.header-wrapper.block-wrapper {
  padding: $header-padding;
}

.row.container.route-header-wrapper {
  margin-top: 20px;
  padding: 0;

  .route-header-addition {
    @media screen and (max-width: #{map-get($breakpoints-down, 'lg')}px) {
      min-height: 2em;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 0.7em;
    }
  }

  .route-title {
    font-family: 'TungstenMedium', sans-serif;
    font-size: 1.7em;
  }
}

.header-nav-wrapper {
  align-items: center;

  .header-logo-wrapper {
    position: relative;
    height: 100%;

    .header-logo {
      width: 10em;
      max-width: 100%;
    }
  }

  .header-menu-switcher {
    position: relative;

    .menu-switcher {
      display: none;
      border: 1px solid $font-color;
      background-color: $white-color;
      white-space: nowrap;
      border-radius: 5px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1000;

      &:empty {
        border: none;
      }

      .menu-switcher-item {
        display: inline-block;
        height: 100%;
        text-align: center;
        border-right: 1px solid $font-color;
        cursor: pointer;

        &:last-child {
          border: none;
        }

        & > a {
          display: inline-block;
          min-width: 7em;
          padding: 10px;
        }

        &, > a {
          color: $font-color;
          text-decoration: none;
        }

        &.active {
          background-color: $font-color;

          &, > a {
            color: $white-color;
          }
        }
      }

      @media screen and (min-width: #{map-get($breakpoints-up, 'lg')}px) {
        &.menu-switcher-desktop {
          display: inline-block;
          overflow: hidden;
        }
      }
      
      @media screen and (max-width: #{map-get($breakpoints, 'lg')}px) {
        &.menu-switcher-mobile {
          display: inline-block;
        }
      }
    }
  }

  .header-account-section {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    padding-left: 10px;
    $avatar-size: 3em;
    $avatar-margin: 15px;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: -$header-padding;
      bottom: -$header-padding;
      width: 1px;
      background-color: $light-gray-color;
    }

    & > * {
      flex: 0 0 auto;
    }

    .header-avatar-wrapper {
      position: relative;
      height: $avatar-size;
      width: $avatar-size;
      margin-right: $avatar-margin;
      display: inline-block;

      .header-avatar {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: url(/assets/img/avatar_empty.png) center/contain no-repeat;
      }
    }

    @media screen and (max-width: #{map-get($breakpoints, 'sm')}px) {
      .header-avatar-wrapper {
        display: none;
      }
    }

    .header-account-info {
      line-height: $avatar-size;
      max-width: calc(100% - #{$avatar-size} - #{$avatar-margin});
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &, > a {
        color: $secondary-color;
      }

      > a {
        margin-right: 15px;
      }

      @media screen and (max-width: #{map-get($breakpoints, 'md')}px) {
        max-width: 100%;
      }
    }
  }

  .sub-menu-wrapper {
    position: absolute;
    overflow: hidden;
    background-color: $white-color;
    right: 50%;
    max-height: 0;
    bottom: -10px;
    border-radius: 5px;
    transform: translate(50%, calc(100% + 15px));
    transition: all 0.5s;
    z-index: 1000;

    &.active {
      padding: 20px 0;
      border: 1px solid $font-color;
      max-height: 200px;
    }

    .sub-menu-list {
      min-width: 10em;

      .sub-menu-item {
        margin: 3px 0;
        padding: 0 25px;
        display: block;
        text-decoration: none;
        color: $font-color;

        &:hover {
          background-color: $light-gray-color;
        }
      }
    }
  }

  .menu-switcher {
    .sub-menu-wrapper {
      &.active {
        padding: 0;
      }
    }
  }

  & > * {
    flex: 0 0 auto;
  }
}