@import 'src/common/scss/variables';

.block-wrapper.dashboard-block.map-row-wrapper {
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
  margin-bottom: 1.1em;
  overflow: hidden;

  .map-info-wrapper {
    padding-top: 1em;
    padding-bottom: 1em;

    .scroll-box {
      max-height: 580px;
      overflow-x: hidden;
      overflow-y: auto;
      margin-top: 1.1em;
      padding: 2px 5px 8px;

      .block-wrapper {
        &:first-child {
          margin-top: 0;
        }
      }
    }

    .carrier-header-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;

      .carrier-icon {
        display: inline-block;
        height: 20px;

        img {
          max-height: 100%;
        }
      }
    }

    .block-section:last-child {
      .carrier-info {

        .carrier-description {
          margin-bottom: 0;
        }
      }
    }

    .carrier-info {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      > * {
        display: inline-block;
      }

      .carrier-description {
        margin-bottom: 15px;
      }

      .carrier-value {
        font-size: 1.8em;
        font-family: 'TungstenBook', sans-serif;
      }
    }
  }

  .map-wrapper {
    min-height: 600px;
    background-color: $dark-color;
    padding-left: 0;

    #usa {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      height: 100%;
    }

    .datamaps-hoverover {
      margin-right: 20px;

      .hoverinfo {
        position: relative;
        border: none;
        border-radius: 8px;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 18px 0px;
        padding: .5em 1em;
        
        .info-icon {
          position: absolute;
          top: .5em;
          right: .5em;
          border-radius: 50%;
          height: 1em;
          width: 1em; 
        }

        .info-item {
          margin-bottom: .6em;

          &:last-child {
            margin-bottom: 0;
          }

          .info-title {
            font-size: .7em;
            margin-bottom: .5em;
          }

          .info-content {
            color: $font-color;
            font-weight: bold;
          }
        }
      }
    }
  }
}