.loading-wrapper {
    position: relative;
    z-index: 999;

    &:before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255,255,255,0.6) url(/assets/img/loading.svg) center/15vh no-repeat;
        z-index: 9999999999;
    }
}