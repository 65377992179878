@import "variables";

//mixin for making grid column
@mixin makeCol($breakpoint) {
  &.col#{$breakpoint}-2 {
    $col-width: 100%/12*2;
    width: calc( #{$col-width} - #{$x-margin * 2} ) ;
  }
  &.col#{$breakpoint}-3 {
    $col-width: 100%/12*3;
    width: calc( #{$col-width} - #{$x-margin * 2} ) ;
  }
  &.col#{$breakpoint}-4 {
    $col-width: 100%/12*4;
    width: calc( #{$col-width} - #{$x-margin * 2} ) ;
  }
  &.col#{$breakpoint}-6 {
    $col-width: 100%/12*6;
    width: calc( #{$col-width} - #{$x-margin * 2} ) ;
  }
  &.col#{$breakpoint}-7 {
    $col-width: 100%/12*7;
    width: calc( #{$col-width} - #{$x-margin * 2} ) ;
  }
  &.col#{$breakpoint}-8 {
    $col-width: 100%/12*8;
    width: calc( #{$col-width} - #{$x-margin * 2} ) ;
  }
  &.col#{$breakpoint}-10 {
    $col-width: 100%/12*10;
    width: calc( #{$col-width} - #{$x-margin * 2} ) ;
  }
  &.col#{$breakpoint}-12 {
    $col-width: 100%/12*12;
    width: calc( #{$col-width} - #{$x-margin * 2} ) ;
  }
}

//mixin for making grid columns for all breakpoints
@mixin makeColBreakPoints() {
  @include makeCol('');

  $reverseBreakpoints: reverse($breakpoints);

  @each $breakpoint-name, $breakpoint in $reverseBreakpoints {
    $i: index(($breakpoints), ($breakpoint-name $breakpoint));
    $modificator: "max-width";
    $breakpoint-up: 1 + $breakpoint;

    @if $i == length($list: $breakpoints) {
      @media screen and (#{$modificator}: #{$breakpoint-up}px){
        @include makeCol('-#{$breakpoint-name}');
      }

      $modificator: "min-width";
    }

    @media screen and (#{$modificator}: #{$breakpoint-up}px){
      @include makeCol('-#{$breakpoint-name}');
    }
  }
}

//function for returning reverse scss map
@function reverse($list, $recursive: false) {
  $result: ();

  @for $i from length($list)*-1 through -1 {
     @if type-of(nth($list, abs($i))) == list and $recursive {
       $result: append($result, reverse(nth($list, abs($i)), $recursive));      
     }

     @else {
       $result: append($result, nth($list, abs($i)));
     }
  }

  @return $result;
}

//mixin for adding custom scrollbar to block
@mixin addDefaultSrollbar() {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    margin: 10px 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $scrollbar-color;
    border-radius: 10px;
  }
}

//mixin for making all color resolution badges
@mixin makeResolutionBadges() {
  @each $resolution-class, $resolution-color in $resolution-colors {
    &.#{$resolution-class} {
      position: relative;
      overflow: hidden;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 4px;
        background-color: $resolution-color;
        z-index: 1;
      }
    }
  }
}

//mixin for making all color resolution table badges
@mixin makeTableResolutionBadges() {
  @each $resolution-class, $resolution-color in $resolution-colors {
    &.#{$resolution-class} {
      border: 1px solid $resolution-color;
      color: $resolution-color;
      background-color: rgba( $resolution-color, .15 );

      .dot {
          background-color: $resolution-color;
      }
    }
  }
}