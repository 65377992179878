$breakpoints: (
  'xs' : 420,
  'sm' : 576,
  'md' : 768,
  'lg' : 992,
  'xl' : 1200
);
$breakpoints-down: (
  'xs' : map-get($breakpoints, 'xs') - 1,
  'sm' : map-get($breakpoints, 'sm') - 1,
  'md' : map-get($breakpoints, 'md') - 1,
  'lg' : map-get($breakpoints, 'lg') - 1,
  'xl' : map-get($breakpoints, 'xl') - 1
);
$breakpoints-up: (
  'xs' : map-get($breakpoints, 'xs') + 1,
  'sm' : map-get($breakpoints, 'sm') + 1,
  'md' : map-get($breakpoints, 'md') + 1,
  'lg' : map-get($breakpoints, 'lg') + 1,
  'xl' : map-get($breakpoints, 'xl') + 1
);

$resolution-colors: (
  'dark-blue'     : #26547c,
  'orange'        : #f3bb6d,
  'light-red'     : #ff8a62,
  'light-blue'    : #0f9aee,
  'light-green'   : #04c179,
  'violet'        : #a575ae,
  'default-black' : #433922
);

$breakpointsLength: length($breakpoints);
$grid-size: 1130;
$col-width: 100%;
$x-margin: 15px;


$main-color: rgb(244, 246, 248);
$secondary-color: rgb(182,180,97);
$btn-background: rgb(229,201,86);
$content-background: rgb(245,245,250);
$warning-color: #f7363e;
$success-color: #04c179;
$dark-color: #433922;
$light-gray-color: #cccccc;
$white-color: #ffffff;

$font-color: #666;
$light-font-color: #DB9D47;

$click-scale-size: 0.97;