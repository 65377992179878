@import 'src/common/scss/variables';

.notification-wrapper {
  margin: 0 auto;
  text-align: center;

  .notification-heading {
    color: $secondary-color;
    font-size: 3rem;
    line-height: 3.2rem;
    font-weight: bold;
  }

  .notification-desc {
    font-size: 0.8rem;
    color: $font-color;
  }

  .m-b-10 {
    margin-bottom: 10px!important;
  }

  .m-b-20 {
    margin-bottom: 20px!important;
  }

  .m-t-20 {
    margin-top: 20px!important;
  }
}