.parent-select{
  cursor: pointer;
  width: 23rem;
  max-width: 75%;
}
.parent-select *{
  /* font-size: 1.6vw; */
}
.parent-select.order-input-wrapper.select-style span {
  font-size: 2.2em;
  display: block;
  margin: auto;
  width: auto;
  height: auto;
}

.form-input .parent-select.order-input-wrapper {
  border: 1px solid transparent;
  display: flex;
  position: relative;
  overflow: visible;
}
.parent-select.order-input-wrapper .header-select{
  width: 100%;
  display: flex;
}
.parent-option-select {
  display: none;
}
.parent-option-select *{
  width: 100%;
}
.active .parent-option-select{
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.option-select {
  padding: 15px 0;
  background: #f4f6f8;
  border: 1px solid #f4f6f8; 
}
.option-select:first-child{
  border-radius: 8px 8px 0 0;
}
.option-select:last-child{
  border-radius: 0 0 8px 8px;  

}
.option-select:hover{
  background: #fff;

}
