@import 'src/common/scss/variables';

.horizontal-chart-wrapper {
    $corner-height: 10px;
    height: 10em;

    .recharts-tooltip-wrapper {
        transition: none !important;
    }

    .horizontal-chart-tooltip-wrapper {
        position: relative;

        &:after {
            bottom: auto;
            top: auto;
            left: 50%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
        }
        
        &:after {
            border-color: transparent;
            border-width: $corner-height;
            margin-left: -$corner-height;
        }
    }

    .recharts-tooltip-wrapper-left {
        .horizontal-chart-tooltip-wrapper {
            transform: translateX(50%);
        }
    }

    .recharts-tooltip-wrapper-right {
        .horizontal-chart-tooltip-wrapper {
            transform: translateX(-50%);
        }
    }

    .recharts-tooltip-wrapper-top {
        .horizontal-chart-tooltip-wrapper {
            padding-bottom: #{$corner-height - 2px};

            &:after {
                top: calc( 100% - #{$corner-height} );
                border-top-color: inherit;
            }
        }
    }

    .recharts-tooltip-wrapper-bottom {
        .horizontal-chart-tooltip-wrapper {
            padding-top: #{$corner-height - 2px};

            &:after {
                bottom: calc( 100% - #{$corner-height} );
                border-bottom-color: inherit;
            }
        }
    }
    
    .horizontal-chart-tooltip-content {
        padding: 1em;
        color: $white-color;
        border: 1px solid $white-color;
        border-radius: 5px;
    }
}